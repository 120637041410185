/** @jsx jsx */
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import RatgeberList from '../../components/Ratgeber/RatgeberList';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

const Expertentipps = ({ data, location }) => {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Expertentipps"
      description="Wertvolle Tipps & Tricks für den Hausverkauf von Experten aus der Branche."
      image={data.hero.childImageSharp.fluid}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Expertentipps"
        subtitle="rund um den Verkauf von Immobilien"
        image={data.hero}
      />
      <Section>
        <div sx={{ marginBottom: 7 }}>
          <Heading>Wertvolle Tipps & Tricks für den Hausverkauf</Heading>
          <Subheading>Von Experten aus der Branche</Subheading>
        </div>
        <RatgeberList ratgeberItems={data.site.siteMetadata.ratgeberList} />
      </Section>
    </HomePageLayout>
  );
};

export default Expertentipps;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        ratgeberList {
          slug
          url
          name
          image {
            childImageSharp {
              fluid(maxWidth: 700, fit: COVER, quality: 90) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          description
        }
      }
    }
    hero: file(name: { eq: "hero-expertentipps" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
